import {
  positionMemoryItem,
  getParams,
  addClickListenersPins,
} from "./memoryExplorerHelpers";


import { fetchMemory, fetchMemories } from "./memoryRepository";
import * as vis from "./visibility";
import onScreen from "./onScreen";
import remToPixels from "./remToPixels";

let lastActive;
let closePins;

const doubleCellNames = ["Online connectivity", "Frustration"];

document.addEventListener("turbolinks:load", () => {
  // The back button when the pins are shown
  closePins = document.getElementById("close-pins");
  closePins?.addEventListener("click", handleClose);

  addClickListenersToMemoryItems();
  addHoverListenersToMemoryItems();
  addClickListenersPins();
});


const activateMemory = (e) => {
  // if the user clicks on child of the cell make sure we get the parent
  const memoryItem = e.target.closest("[data-memory-item]");

  // the currentActive category is the one that is clicked
  let currentActive = memoryItem;

  const img = memoryItem.querySelector("img");

  if(window.innerWidth > 640){
    // 'Zoom in' on the clicked category image 
    const imgWidth = img.offsetWidth;
    const windowWidth = window.innerWidth;
    let maxScale = windowWidth / imgWidth;
    if(img.offsetHeight * maxScale > window.innerHeight) {
      maxScale = window.innerHeight / img.offsetHeight;
    }
    const scale = maxScale * 0.8;

    // get left position relative to viewport
    const { left, top, bottom, right } = memoryItem.getBoundingClientRect();

    const offsetMultiplier = 1.0 / scale;
    const leftAfterScale = left - (imgWidth * offsetMultiplier);
    const rightAfterScale = right + (imgWidth * offsetMultiplier);
    const topAfterScale = top - (img.offsetHeight * offsetMultiplier);
    const bottomAfterScale = bottom + (img.offsetHeight * offsetMultiplier);
    let xOffset = 0.0;
    let yOffset = 0.0;

    if(leftAfterScale < 0){
      xOffset = Math.abs(leftAfterScale);
    } else if(rightAfterScale > windowWidth) {
      xOffset = windowWidth - rightAfterScale;
    }
    if(topAfterScale < 0){ 
      yOffset = Math.abs(topAfterScale);
    } else if(bottomAfterScale > window.innerHeight) {
      yOffset = window.innerHeight - bottomAfterScale;
    }

    img.style.transform = `scale(${scale}) translate(${xOffset}px, ${yOffset}px)`;
  } else {
    img.style.transform = `scale(1.2)`;
  }

  

  // // deactivate the previously active category
  if (lastActive) lastActive.classList.remove("active");

  // // make it active
  currentActive.classList.add("active");

  // Two of the categories are in the same cell so we need to add class  to that
  // cell as well otherwise we are hiding it in the css
  if (doubleCellNames.includes(currentActive.getAttribute("data-name"))) {
    currentActive.parentElement.parentElement.classList.add("active");
  }

  let memoryGrid = document.getElementById("memory-grid");

  // Adding has-active to the memory grid will hide every cell of the grid
  // that does not have the class active on it
  memoryGrid.classList.add("has-active");
  // hide intro text
  const intro = document.querySelector(".intro.toggle-trans");
  if(intro) intro.classList.add('trans');

  renderClickMarkersText();
  // getting the memory content div and appending the pins to it
  renderPins(currentActive);
  // renderLabel(currentActive)

  // NOTE: We hide this selector just now
  // document.getElementById("period-select").addEventListener("change", (e) => {
  //   renderPins(currentActive);
  // });

  // Show BACK BUTTON
  renderClosePins();
  // hide the filter
  vis.fadeOutById('top-left')
  // hide bg
  toggleBg();

  lastActive = memoryItem;
  memoryItem.removeEventListener("click", activateMemory);
};

const renderPins = (currentActive) => {
  let params = getParams(currentActive);
  
  // Removing the use of period
  // fetchMemories(params.category_id, params.period)
  //   .then((memories) => buildMemories(memories));
  
  fetchMemories(params.category_id)
    .then((memories) => buildMemories(memories));
};


const renderClosePins = () => {
  closePins.style.position = 'fixed';
  closePins.style.top = headerAvoidingValue(2);
  closePins.style.left = '2rem';
  closePins.classList.remove("trans");
}

// calculate area of header that is onscreen
const getHeaderVisibleHeight = () => {
  const header = document.querySelector('header');
  const headerRect = header.getBoundingClientRect();
  const headerHeight = headerRect.bottom - headerRect.top;
  const headerWidth = headerRect.right - headerRect.left;
  const headerArea = headerHeight * headerWidth;
  const headerOnScreenArea = headerArea - (headerRect.top * headerWidth);
  const headerOnScreenPercentage = headerOnScreenArea / headerArea;
  const headerOnScreenHeight = headerHeight * headerOnScreenPercentage;
  return headerOnScreenHeight;
}

const headerAvoidingValue = (remValue) => {
  if(onScreen(document.querySelector('header'))){
    return `${getHeaderVisibleHeight() + remToPixels(remValue)}px`;
  } else {
    return remValue + 'rem';
  }
}

// show "Click markers..." text
const renderClickMarkersText = () => {
  const clickMarkersText = document.getElementById('click-markers-text');
  if(clickMarkersText) {
    clickMarkersText.style.top = headerAvoidingValue(2);
    clickMarkersText.classList.remove('trans');
    setTimeout(() => clickMarkersText.classList.add('trans'), 3000)
  }

}


const addHoverListenersToMemoryItems = () => {
  const memoryItems = document.querySelectorAll("[data-memory-item]");
  memoryItems.forEach((m) => m.addEventListener("mouseenter", renderLabel));
  memoryItems.forEach((m) => m.addEventListener("mouseout", hideLabels));
}

const renderLabel = (e) => {
  const current = e.target;
  const name = current.getAttribute("data-name");
  if(name){
    const label = document.getElementById("map-label");
    const smallLabel = document.getElementById("map-label-small");

    label.innerText = name;
    smallLabel.innerText = name;

    // Set position for Medium+ Screens label
    label.style.top = e.clientY + 'px';
    label.style.left = e.clientX  + 'px';

    label.classList.remove("trans");
    smallLabel.classList.remove("trans");
  }
}

const hideLabels = () => {
  const label = document.getElementById("map-label");
  const smallLabel = document.getElementById("map-label-small");
  if(label) label.classList.add('trans');
  if(smallLabel) smallLabel.classList.add('trans');
}


const buildMemories = (memories) => {
  memories.forEach((memory) => {
    let memoryItem = document.createElement("div");
    memoryItem.classList.add("pin");
    memoryItem.setAttribute("data-memory-id", memory.id);
    const container = document.querySelector('#map-container');
    setTimeout(() => {
      container.appendChild(memoryItem);
      positionMemoryItem(memoryItem);
    }, 1000);
    
  });
};

// Fetches the memory by id and populates the memory popup with the body
// also renders a close button
const addClickListenersToMemoryItems = () => {
  const memoryItems = document.querySelectorAll("[data-memory-item]");
  memoryItems.forEach((memoryItem) =>
    memoryItem.addEventListener("click", activateMemory)
  );
};

const handleClose = () =>  {
  // show filter
  vis.fadeInById('top-left')
  // close popup
  vis.hideById("memory-popup");
  // show bg
  document.getElementById('rt-bg').style.opacity = 0.8;
  if(lastActive) lastActive.querySelector('img').style = '';
  removePins();
  hideLabels();
  addClickListenersToMemoryItems();
  // Hide the back button and put back in position
  closePins.classList.add('trans');
  // hide intro text
  const intro = document.querySelector(".intro.toggle-trans");
  if(intro) intro.classList.remove('trans');
}

const toggleBg = () => {
  const bg = document.getElementById('rt-bg');
  if(bg.style.opacity = 0) {
    bg.style.opacity = 0.8;
  } else {
    bg.style.opacity = 0;
  }
}

const removePins = () => {
  const pins = document.querySelectorAll(".pin");
  pins.forEach((pin) => {
    pin.style.opacity = 0;
    setTimeout(() => pin.remove(), 1000);
  });
}