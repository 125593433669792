export function copyToClipBoard(text_to_copy) {
  
    navigator.clipboard.writeText(text_to_copy).then(function() {
        
    }).catch(function(err) {
        console.log('Something went wrong', err);
    });

}
export default copyToClipBoard;
  