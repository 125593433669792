const logged_in = () => {
    var myValue = document.querySelector("input#logged_in").value;
    var setLoggedIn;

    if (myValue == 'true') {
        setLoggedIn = true;
    } else {
        setLoggedIn = false;
    }

    return setLoggedIn;
}

export default logged_in;