// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "trix"
import "@rails/actiontext"
import jquery from "jquery"
require("@nathanvda/cocoon")


Rails.start()
Turbolinks.start()
ActiveStorage.start()


require('foundation-sites');
require('app/map');
require('app/mapData');
require('app/loggedIn');

const startFoundation = () => {
  $(function () { $(document).foundation(); });
}

$(document).on('turbolinks:load', function () {
  startFoundation();
});

require("trix")
require("@rails/actiontext")
require("app/analytics.js.erb");
require("app/submitFormOnChange");
require("app/square");
require("app/direct_uploads");
require("app/triggerFileAttached");
require("app/setDataIndexForMediaInputs");
require("app/shiftAddAssociationButtonAfterInsert");
require("app/mapInfoBox");
require("app/newMemoryWizzardForm");
require("app/memoryExplorer");
require("app/copyPopUpLink");
require("app/setTrixToolbar");
require("app/embedsController");

import newMemoryWizzardForm from "../app/newMemoryWizzardForm"

export {
  newMemoryWizzardForm,
  startFoundation
}




