class WizzardForm {
  constructor(form_id, field_wrapper_class) {
    this.form = document.getElementById(form_id);
    this.field_wrapper_class = field_wrapper_class;
    this.fields = this.form.querySelectorAll(`.${this.field_wrapper_class}`);
    this.fields_count = this.fields.length;
    this.current_step = 0;
    this.previous_button = this.form.querySelector("[data-wizzard-previous]");
    this.next_button = this.form.querySelector("[data-wizzard-next]");
    this.close_button = this.form.querySelector("[data-wizzard-close]");
    this.formValid = true;

    this.previous_button.addEventListener("click", (e) => {
      e.preventDefault();
      this.previousStep();
    });

    this.next_button.addEventListener("click", (e) => {
      e.preventDefault();
      this.nextStep();
    });

    this.init();
  }
 
  updateSteps() {
    this.fields.forEach((field, index) => {
      field.style.display = index === this.current_step ? "block" : "none";
    });
    this.hideShowControls();
  }
  
  init() {
    this.updateSteps();
  }

  nextStep() {
    if (this.current_step >= this.fields_count) {
      return;
    }

    this.current_step++;
    if (this.current_step === this.fields_count - 1) {
      this.next_button.style.display = "none";
    } else {
      this.next_button.style.display = "inline-block";
    }
    this.updateSteps();
  }

  previousStep() {
    if (this.current_step <= 0) {
      return;
    }
    this.current_step--;
    this.updateSteps();
  }

  hideShowControls() {
    if(!this.formValid) {
      this.previous_button.style.display = "none";
      this.next_button.style.display = "none";
      // this.close_button.style.display = "none";
    }
    if (this.current_step === 0) {
      this.previous_button.style.display = "none";
      this.close_button.style.display = "inline-block";
    } else {
      this.previous_button.style.display = "inline-block";
      this.close_button.style.display = "none";
    }

    if (this.current_step >= this.fields_count - 1) {
      this.next_button.style.display = "none";
      // this.close_button.style.display = "none";
    } else {
      this.next_button.style.display = "inline-block";
      // this.close_button.style.display = "none";
    }
  }
}

export default WizzardForm;
