import copyToClipBoard from "./copyToClipboard";

document.addEventListener("turbolinks:load", () => {
  const copy_button = document.getElementById("copy-link");
  if (copy_button) {
    copy_button.addEventListener("click", (event) => {
      event.preventDefault();
      const link = copy_button.getAttribute("data-link");
      copyToClipBoard(link);
      copy_button.innerHTML = "Copied";
    });
  }
});
