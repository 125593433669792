import mapboxgl from "mapbox-gl";
import * as mapData from "./mapData";
import * as vis from "./visibility";
import logged_in from "./loggedIn";

let mapContainer;
let map;
let addMemoryButton;
// detect map and setup
var initMap = () => {
  if (mapboxgl.supported()) {
    mapContainer = document.getElementById("map");
    if (mapContainer) {
      map = mapData.setupMap("map");
      map.addControl(new mapboxgl.NavigationControl(), "bottom-left");
      // loclhost:3000/locations.json
      map.on('load', () => {
        addMemoryButton = document.getElementById("addMemory");
        if (addMemoryButton) {
          addMemoryButton.innerHTML = "Add Memory";
          vis.hideById("info");
          addMemoryButton.addEventListener("click", allowMemoryAddition);
        }
        mapData.addGeoJsonLayer(map, 'memories.json');
        map.on('click', 'memories-points', (e) => {
          // Copy coordinates array.
          vis.hideById("infoModal");
          const coordinates = e.features[0].geometry.coordinates.slice();
          const properties = e.features[0].properties;
          const description = JSON.parse(properties.description).body.split(' ').slice(0, 40).join(' ') + '...<br><br>';
          //const message = "<i>Click below to read more about this memory, explore what people have added to it or add your own multimedia.</i><br><br>";

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          const loggedIn = logged_in();

          const html = `
          <div id="memoryModal" style="width:325px;height:auto;">
            <div class="content relative">
            <img src="https://ardnamurchan-bucket.s3.eu-west-2.amazonaws.com/close.svg" id="memoryCloseBtn" class="close-btn">
            <h4 class="lmt"> ${properties.title} </h4>
            ${description}<a class="button small" href="/memories/${properties.id}">more</a>
            <a class="button small" href="/memories/${properties.id}/edit">add something</a>
            ${loggedIn ? '<a class="button small" href="/memories/' + properties.id + '/edit">edit</a>' : ''}
            </div>
          </div>
        `
    

        const popup = new mapboxgl.Popup({ closeButton: false })
            .setLngLat(coordinates)
            .setHTML(html)
            .addTo(map);

          

         document.getElementById('memoryCloseBtn').addEventListener('click', () => popup.remove());
        });
      });
    }
  }
};

const showPopup = (e) => {
  const { coordinates } = e.features[0].geometry
  const properties = e.features[0].properties;
  const description =
    properties.description.split(" ").slice(0, 40).join(" ") + "...<br><br>";
  const message =
    "<i>Click below to read more about this memory, explore what people have added to it or add your own multimedia.</i><br><br>";

  // Ensure that if the map is zoomed out such that multiple
  // copies of the feature are visible, the popup appears
  // over the copy being pointed to.
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  }



  const html = `
   <h4> ${properties.title} </h4>
   ${description} ${message} <a class="button small" href="/memories/${properties.id
    }">More</a>
   ${logged_in()
      ? '<a class="button small" href="/memories/' +
      properties.id +
      '/edit">Edit</a>'
      : ""
    }
 `;

  new mapboxgl.Popup("className: mapboxgl-popup-content")
    .setLngLat(coordinates)
    .setHTML(html)
    .addTo(map);
};

const getCoordinatesAndGoToSubmissionForm = (e) => {
  const coordinates = [e.lngLat.lng, e.lngLat.lat];
  window.location.href = `/memories/new?coordinates=${coordinates}`;
};

const allowMemoryAddition = (e) => {
  map.getCanvas().style.cursor = "crosshair";
  map.on("click", getCoordinatesAndGoToSubmissionForm);
  vis.showById("info");
  addMemoryButton.innerHTML = "Cancel";
  addMemoryButton.removeEventListener("click", allowMemoryAddition);
  addMemoryButton.addEventListener("click", cancelMemoryAddition);
};

const cancelMemoryAddition = (e) => {
  map.getCanvas().style.cursor = "";
  map.off("click", getCoordinatesAndGoToSubmissionForm);
  addMemoryButton.innerHTML = "Add Memory";
  addMemoryButton.removeEventListener("click", cancelMemoryAddition);
  addMemoryButton.addEventListener("click", allowMemoryAddition);
  vis.hideById("info");
};

document.addEventListener("turbolinks:load", initMap);
