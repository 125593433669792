export const listenById = (event, selector, callback) => {
  let element = document.getElementById(selector);
  if(element){
    element.addEventListener(event,callback);
  }
}

export const listenBySelector = (event, selector, callback) => {
  let element = document.querySelector(selector);
  if(element){
    element.addEventListener(event,callback);
  }
}