/**
 *
 * The idea behind this file is the abstract the way fetch those memories from the server.
 * Maybe we could you use the Repository pattern
 * For now we just separte the fetching from the memoryExplorer.js file
 *
 */

export const fetchMemory = async (memoryId) => {
  const reponse = await fetch(`/memories/${memoryId}.json`);
  const memory = await reponse.json();
  return memory;
};

export const fetchMemories = async (categoryId, period = null) => {
  const thePeriod = period !== null ? `&period=${period}` : "";
  const reponse = await fetch(
    `/memories.json/?category_id=${categoryId}${thePeriod}`
  );
  const memories = await reponse.json();
  return memories;
};
