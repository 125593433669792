import {fetchMemory} from "./memoryRepository";


export const hideHeaderAndFooter = () => {
  document.getElementsByTagName("header")[0].classList.add("hidden");
  document.getElementsByTagName("footer")[0].classList.add("hidden");
}

export const showHeaderAndFooter = () => {
  document.getElementsByTagName("header")[0].classList.remove("hidden");
  document.getElementsByTagName("footer")[0].classList.remove("hidden");
}

export const getRandomPosition = () => {
  let top = Math.floor(Math.random() * 100) + "%";
  let left = Math.floor(Math.random() * 100) + "%";
  return { top, left };
};

export const positionMemoryItem = (memoryItem) => {
  let randomPosition = getRandomPosition();
  memoryItem.style.top = randomPosition.top;
  memoryItem.style.left = randomPosition.left;
};

export const getParams = (currentActive) => {
  let params = {};
  params.category_id = currentActive.getAttribute("data-category-id");
  // params.period = document.getElementById("period-select").value;
  return params;
};


export const addClickListenersPins = () => {
  document.addEventListener('click', (e) => {
    if(e.target.classList.contains('pin')){
      showMemory(e.target.getAttribute('data-memory-id'))
    }
  })
}


const showMemory = (memoryId) => {
  if(memoryId){
    fetchMemory(memoryId)
      .then((memory) => {

        const popupHtml = `
        <div class="memory-popup-content grid-x show-for-medium">
          <i class="fa fa-times close-btn absolute top right" data-toggle="memory-popup"></i>
          <div class="cell large-8 memory-body">
            ${memory.body.body}
          </div>
          <div class="cell large-4 memory-icons">
            <img src="${memory.category.image}" alt="like" class="category-image">
            <p> A ${memory.period} about ${memory.category.name.toLowerCase()}</p>
            <div class="postcard-line"></div>
            <div class="postcard-line"></div>
            <div class="postcard-line"></div>
          </div>
        </div>
        
        <div class="memory-popup-content hide-for-medium">
          <i class="fa fa-times close-btn absolute top right" data-toggle="memory-popup"></i>
          <div class="memory-body">
            ${memory.body.body}
          </div>
          <div class="memory-icons">
            <img src="${memory.category.image}" alt="like" class="category-image">
            <p> A ${memory.period} about ${memory.category.name.toLowerCase()}</p>
            <div class="postcard-line"></div>
            <div class="postcard-line"></div>
            <div class="postcard-line"></div>
          </div>
        `

        let popup = document.getElementById("memory-popup");
        popup.classList.remove("hidden");
        popup.innerHTML = popupHtml;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};