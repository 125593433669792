import WizzardForm from "./wizzardForm";

document.addEventListener("turbolinks:load", newMemoryWizzardForm);


export function newMemoryWizzardForm() {
  if (document.getElementById("new_memory")) {
    let memoryWizzardForm = new WizzardForm("new_memory", "wizzard-section");
    memoryWizzardForm.init();

    addEventListener("trix-change", (event) => {
      const { editor } = event.target;
      const string = editor.getDocument().toString();
      const characterCount = string.length - 1;

      if (characterCount > 0) {
        memoryWizzardForm.next_button.disabled = false;
        memoryWizzardForm.next_button.classList.remove('ignore-click');
        memoryWizzardForm.previous_button.disabled = false;
        document.getElementById("memory-submit").disabled = false;
      } else {
        memoryWizzardForm.next_button.disabled = true;
        memoryWizzardForm.next_button.classList.add('ignore-click');
        memoryWizzardForm.previous_button.disabled = true;

        document.getElementById("memory-submit").disabled = true;
      }
    });
  }
}

export default newMemoryWizzardForm;