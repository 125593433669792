import * as vis from './visibility'
import { listenById } from './listener';
const showMapInfoBox = () => {
  vis.toggleById('infoModal');
}

const hideMapInfoBox = () => {
  vis.hideById('infoModal');
}

const hideInstrctionsBox = () => {
  vis.hideById('instuctionsBox');
}
document.addEventListener("turbolinks:load", () => {
  listenById('click', 'infoBar', showMapInfoBox);
  listenById('click', 'infoModalClose', hideMapInfoBox);
  listenById('click', 'getStartedButton', hideMapInfoBox);
  listenById('click', 'instuctionsBoxClose', hideInstrctionsBox);

});