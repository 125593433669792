import * as vis from './visibility';
import Trix from "trix";
import Rails from "@rails/ujs";

class EmbedController {
  constructor(element) {
    this.element = element;
    this.editor = element.editor;
    this.toolbar = element.toolbarElement;
    this.addEmbedButton = this.toolbar.querySelector("#add-embed-button");
    this.popup;
    this.form;
    this.editButtons;
    this.submitButton;

    this.addEmbedButton.addEventListener(
      "click",
      this.initalizeAndShowPopup.bind(this)
    );
  }

  initalizeAndShowPopup() {
    this.initalizePopup();
    this.installPopupEventHandlers();
    this.showPopup();
  }

  initalizePopup() {
    console.log("initalizePopup");
    this.popup = document.querySelector("#new-embed-popup-container");
    console.log("popup: ", this.popup);
    this.form = this.popup.querySelector("form");
    console.log("form: ", this.form);
    this.submitButton = this.popup.querySelector('button[type="submit"]');
    console.log("submitButton: ", this.submitButton);

    this.closeButton = this.popup.querySelector(".close-button");
  }

  installPopupEventHandlers() {
    this.submitButton.addEventListener(
      "click",
      this.handleUploadFormSubmit.bind(this)
    );
    this.closeButton.addEventListener("click", this.reset.bind(this));
  }

  showPopup() {
    vis.show(this.popup);
  }

  handleUploadFormSubmit(e) {
    e.preventDefault();
    const form = new FormData(this.form);
    const data = new URLSearchParams(form).toString();
    Rails.ajax({
      url: this.form.action,
      type: "post",
      data: data,
      error: this.handleError.bind(this),
      success: this.handleSuccess.bind(this),
    });

    this.reset();
  }

  handleError(error) {}

  // Create an Attachment from the response and add to the editor
  handleSuccess(response) {
    let attachment = new Trix.Attachment(response);

    this.editor.insertAttachment(attachment);
    this.element.focus();
    this.reset();
  }

  // add destroy button to attachment

  reset() {
    vis.hide(this.popup);
    this.form.reset();
  }
}

// Load this when the Trix editor is initialized

document.addEventListener("trix-initialize", function (event) {
  new EmbedController(event.target);
});


